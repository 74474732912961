<template>
  <div
    class="page-index-container --new"
    :style="{
      height: $isMobile ? `${$mobileScreenHeight}px` : '100vh'
    }"
  >
    <div class="index-hero-container">
      <div class="index-hero__slideshow-container jsCollection" v-if="lists">
        <div
          class="slideshow__item jsItem"
          v-for="(slide, index) in lists"
          :key="index"
          @click="jump(slide.link)"
        >
          <div
            class="bg"
            :style="{
              backgroundImage: `url(${
                $isMobile ? slide.src.mobile : slide.src.pc
              })`
            }"
          ></div>
          <div class="content">
            <p
              class="slide__name jsTextFx"
              v-html="renderBr(slide.name.en)"
            ></p>
            <div class="slide__line-text">
              <p class="slide__type jsTextFx">{{ slide.category_text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="index-hero-directions">
        <div class="prev"></div>
        <div class="next"></div>
      </div>
    </div>
    <div class="index-side-bar">
      <div class="circle jsCanvas"></div>
      <div class="bottom-desc">
        <p class="text">
          &lt;&nbsp;<span class="currentNub">01</span>&nbsp;/&nbsp;04&nbsp;&gt;
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { slideShow } from "@/assets/utils/common";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      lists: null,
      slides: [
        {
          url: `${process.env.VUE_APP_STATIC_PATH}/static/images/p1.jpg`,
          name: "Shenzhen SCE Yunjingfu<br/>Model Room",
          type: "Residence"
        },
        {
          url: `${process.env.VUE_APP_STATIC_PATH}/static/images/p2.jpg`,
          name: "Comngqing Yunjing<br/>Model Room",
          type: "Residence"
        },
        {
          url: `${process.env.VUE_APP_STATIC_PATH}/static/images/p3.jpg`,
          name: "Henan Yongjingtai<br/>Model Room-85",
          type: "Residence"
        },
        {
          url: `${process.env.VUE_APP_STATIC_PATH}/static/images/p4.jpg`,
          name: "Guobin 1 - Sales Officez",
          type: "show space"
        }
      ]
    };
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  destroyed() {},
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      this.PAGE_LOADING();
      this.getList()
        .then(res => {
          this.lists = res.data.lists;
          if (this.lists) {
            this.$nextTick(() => {
              slideShow.init();
            });
          }
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.PAGE_LOADED();
        });
    },
    getList() {
      return this.$request.get("/api/scroll/home");
    },
    renderBr(str) {
      return str.replace(/\n/g, "<br/>");
    },
    jump(link) {
      if (link) {
        window.location.href = link;
      }
    }
  }
};
</script>

<style></style>
